// @flow
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Icon from '@material-ui/core/Icon';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";
import MainMenu from './components/MainMenu';
import DumbellDialog from './components/DumbellDialog';
import BarbellDialog from './components/BarbellDialog';
import MachineDialog from './components/MachineDialog';
import ImplementsDialog from './components/ImplementsDialog';
import Backend from "./services/backend";
import ContextDTO from "./domain/ContextDTO";
import ErrorDescription from "./domain/ErrorDescription";
import AppSettings from './settings'
import i18n from "./i18n/i18n";

function getUrlParameterByName(name, defaultValue) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return defaultValue;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const settings = AppSettings[window.location.hostname]

const tgWebApp = window.Telegram.WebApp;

const styles = theme => ({
    appBar: {
        backgroundColor: '#636066'
    },
    appContent: {
        marginTop: 80
    },
    savedMessage: {
        color: green[500],
        position: 'absolute',
        bottom: '50%',
        marginLeft: '-50px'
    },
    fab: {
        transition: 'all 500ms',
        position: 'fixed',
        zIndex:999,
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer'
        },
        '& img': {
            transition: 'all 500ms'
        }
    },
    fabBottom: {
        bottom: theme.spacing(4),
        right: theme.spacing(2),
        '& img': {
            width: 60
        }
    },
    fabCenter: {
        bottom: '40%',
        right: '50%',
        marginRight: '-50px',
        '& img': {
            width: 100
        }
    },
    fabHidden: {
        display: 'none'
    },
    flex: {
        flex: 1,
        textAlign: 'left'
    },
    listBar: {
        marginTop: 70
    },

    notificationText: {
        textAlign: 'center',
    },
    successNotificationText: {
        color: green[500],
    },
    errorNotificationText: {
        color: red[500],
    },

    notificationIcon: {
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 20,
        fontSize: 100
    },
    successNotificationIcon: {
        color: green[500]
    },
    errorNotificationIcon: {
        color: red[500]
    },

    notificationComment: {
        textAlign: 'center',
        marginTop: 20,
    },

    loadError: {
        marginTop: 100
    },

    progress: {
        textAlign: 'center',
        marginTop: 100
    },

    progress1: {
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 30
    }
});

const backend = new Backend(settings.apiUrl, getUrlParameterByName('c', 'test'));

class App extends React.Component {

    static STATE_LOADING = "loading";
    static STATE_LOAD_ERROR = "load error";
    static STATE_MENU = "menu";
    static STATE_DUMBELL = "dumbell";
    static STATE_BARBELL = "barbell";
    static STATE_MACHINE = "machine";
    static STATE_IMPLEMENTS = "implements";
    static STATE_SAVING = "saving";
    static STATE_SAVED = "saved";
    static STATE_SAVE_ERROR = "save error";

    state = {
        activeState: App.STATE_LOADING,
        selectedDumbells: [],
        selectedBarbellPlates: [],
        selectedBarbellBars: [],
        selectedMachines: [],
        selectedImplements: [],
        machineWeights: [],
        errorDescription: {},
        changed: false,
        notifyQueue: null,
        userData: {}
    };

    setActiveState = (newActiveState) => {
        return () => {
            this.setState({...this.state, activeState: newActiveState});
        }
    };

    handleDumbellClose = (selectedDumbells) => {
        this.setState({...this.state, activeState: App.STATE_MENU, selectedDumbells: selectedDumbells, changed: true});
    };

    handleBarbellClose = (selectedPlates, selectedBars) => {
        this.setState({...this.state, activeState: App.STATE_MENU, selectedBarbellPlates: selectedPlates, selectedBarbellBars: selectedBars, changed: true});
    };

    handleImplementsClose = (selectedImplements) => {
        this.setState({...this.state, activeState: App.STATE_MENU, selectedImplements: selectedImplements, changed: true});
    };

    handleMachinesClose = (selectedMachines, machineWeights) => {
        this.setState({...this.state, activeState: App.STATE_MENU, selectedMachines: selectedMachines, machineWeights: machineWeights, changed: true});
    };

    handleSave = () => {
        console.log("SAVE")
        console.log(this.state)
        const contextDTO = new ContextDTO(
            this.state.selectedBarbellBars,
            this.state.selectedBarbellPlates,
            this.state.selectedDumbells,
            this.state.selectedMachines,
            this.state.selectedImplements,
            this.state.machineWeights,
            this.state.closed,
            this.state.notifyQueue,
            this.state.userData
        );

        this.setState(
            {...this.state, activeState: App.STATE_SAVING},
            () => {
                backend.update(contextDTO)
                    .then(result => {
                        if (result.status === 200)
                            this.setState({...this.state, activeState: App.STATE_SAVED, changed: false});
                        else
                            this.setState({...this.state, activeState: App.STATE_SAVE_ERROR});
                    })
                    .catch((errorDescription: ErrorDescription) => {
                        this.setState({
                            ...this.state,
                            errorDescription: errorDescription,
                            activeState: App.STATE_SAVE_ERROR
                        })
                    })
            }
        );
    };

    componentDidMount(): void {
        tgWebApp.ready();
        tgWebApp.expand();

        backend.get()
            .then((result: ContextDTO) => {
                this.setState(
                    {...this.state,
                        selectedBarbellPlates: result.availableBarbellPlates,
                        selectedBarbellBars: result.availableBarbellBars,
                        selectedDumbells: result.availableDumbells,
                        selectedMachines: result.availableMachines,
                        selectedImplements: result.availableImplements,
                        machineWeights: result.machineWeights,
                        activeState: result.closed ? App.STATE_SAVED : App.STATE_MENU,
                        notifyQueue: result.notifyQueue,
                        userData: result.userData
                    })
            })
            .catch((errorDescription: ErrorDescription) => {
                this.setState({
                    ...this.state,
                    errorDescription: errorDescription,
                    activeState: App.STATE_LOAD_ERROR
                })
            })
    }

    render() {
        const {classes} = this.props;
        const {activeState, errorDescription, changed} = this.state;

        return (
            <div className="App">
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="button" color="inherit" className={classes.flex}>
                        </Typography>
                        <Button href="#" color="inherit" onClick={this.handleSave} disabled={!changed} className={!changed ? '' : 'blink'}>
                            {i18n.t('App.send')}&nbsp;
                            <Icon>send</Icon>
                        </Button>
                    </Toolbar>
                </AppBar>

                <div className={classes.appContent}>

                    {activeState === App.STATE_LOADING &&
                    <div className={classes.progress}>
                        <CircularProgress/>
                    </div>
                    }

                    {activeState === App.STATE_LOAD_ERROR &&
                    <div className={classes.loadError}>
                        <div className={classNames([classes.notificationIcon, classes.errorNotificationIcon])}>
                            <i className="fas fa-exclamation-circle"></i>
                        </div>
                        <div className={classNames([classes.notificationText, classes.errorNotificationText])}>
                            {i18n.t('App.error_loading_data')}
                        </div>
                        <Divider/>
                        <div className={classes.notificationComment}>
                            {i18n.t('App.load_error_1')}<br/>
                            <p><b>{errorDescription.status}</b></p>
                            {i18n.t('App.load_error_2')}
                        </div>
                    </div>
                    }


                    {activeState !== App.STATE_LOAD_ERROR && activeState !== App.STATE_LOADING &&
                    <MainMenu
                        onDumbells={this.setActiveState(App.STATE_DUMBELL)}
                        onBarbells={this.setActiveState(App.STATE_BARBELL)}
                        onMachines={this.setActiveState(App.STATE_MACHINE)}
                        onImplements={this.setActiveState(App.STATE_IMPLEMENTS)}
                    />
                    }
                </div>

                <DumbellDialog
                    open={activeState === App.STATE_DUMBELL}
                    selected={this.state.selectedDumbells}
                    onClose={this.handleDumbellClose}
                />

                <BarbellDialog
                    open={activeState === App.STATE_BARBELL}
                    selectedPlates={this.state.selectedBarbellPlates}
                    selectedBars={this.state.selectedBarbellBars}
                    onClose={this.handleBarbellClose}
                />

                <MachineDialog
                    open={activeState === App.STATE_MACHINE}
                    selected={this.state.selectedMachines}
                    selectedWeights={this.state.machineWeights}
                    onClose={this.handleMachinesClose}
                />

                <ImplementsDialog
                    open={activeState === App.STATE_IMPLEMENTS}
                    selected={this.state.selectedImplements}
                    onClose={this.handleImplementsClose}
                />

                <Dialog
                    id="notificationDialog"
                    open={activeState === App.STATE_SAVED || activeState === App.STATE_SAVE_ERROR || activeState === App.STATE_SAVING}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText>
                            {activeState === App.STATE_SAVING &&
                            <div>
                                <div className={classes.progress1}>
                                    <CircularProgress/>
                                </div>
                                <div
                                    className={classNames([classes.notificationText, classes.successNotificationText])}>
                                    {i18n.t('App.saving')}
                                </div>
                            </div>
                            }
                            {activeState === App.STATE_SAVED &&
                            <div>
                                <div
                                    className={classNames([classes.notificationIcon, classes.successNotificationIcon])}>
                                    <i className="far fa-check-circle"></i>
                                </div>
                                <div
                                    className={classNames([classes.notificationText, classes.successNotificationText])}>
                                    {i18n.t('App.save_success_1')}
                                </div>
                                <Divider/>
                                <div className={classes.notificationComment}>
                                    {i18n.t('App.save_success_2')}
                                </div>
                            </div>
                            }
                            {activeState === App.STATE_SAVE_ERROR &&
                            <div>
                                <div className={classNames([classes.notificationIcon, classes.errorNotificationIcon])}>
                                    <i className="fas fa-exclamation-circle"></i>
                                </div>
                                <div className={classNames([classes.notificationText, classes.errorNotificationText])}>
                                    {i18n.t('App.save_error')}
                                </div>
                                <Divider/>
                                <div className={classes.notificationComment}>
                                    {i18n.t('App.save_error_1')}<br/>
                                    <p><b>{errorDescription.status}</b></p>
                                    {i18n.t('App.save_error_2')}
                                </div>
                            </div>

                            }
                        </DialogContentText>
                    </DialogContent>
                    {activeState === App.STATE_SAVE_ERROR &&
                    <DialogActions>
                        <Button onClick={this.setActiveState(App.STATE_MENU)} color="primary">
                            {i18n.t('App.ok')}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
            </div>

        )
    }
}

export default withStyles(styles, {withTheme: true})(App)
