//@flow

const AppSettings = {
    "10.0.1.124": {
        apiUrl: "https://eqp-fe.gbbt.ru"
    },

    "localhost": {
        apiUrl: "http://localhost:8099"
    },

    "eqp.ptbt.ru": {
        apiUrl: "https://eqp.ptbt.ru"
    },

    "eqp.ptbt.site": {
        apiUrl: "https://eqp.ptbt.site"
    },

    "equipment.ptbt.ru": {
        apiUrl: "https://eqp.ptbt.ru"
    },

    "eqp-fe.gbbt.ru": {
        apiUrl: "https://eqp-fe.gbbt.ru"
    },
};


export default AppSettings