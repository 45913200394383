//@flow

import ContextDTO from "../domain/ContextDTO";
import ErrorDescription from "../domain/ErrorDescription";

class Backend {

    contextUrl: string;
    contextId: string;
    notifyQueue;

    constructor(url: string, contextId: string) {
        this.contextUrl = url;
        this.contextId = contextId;
    }

    get(): ContextDTO {
        return fetch(this.contextUrl + '/redis/' + this.contextId + '/get')
            .then(Backend._checkResponse)
            .then((response) => response.json())
            .then((responseJson) => Backend._parseContextDTO(responseJson))
    }

    update(dto: ContextDTO) {
        return fetch(this.contextUrl + '/redis/' + dto.notifyQueue + '/rpush',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dto)
            })
            .then(Backend._checkResponse)
    }

    static _checkResponse(response) {
        if (response.status === 200)
            return response;

        return response.json().then(json => {
            throw new ErrorDescription(json.status)
        });
    }

    static _parseContextDTO(json: any) {
        return new ContextDTO(
            json.availableBarbellBars,
            json.availableBarbellPlates,
            json.availableDumbells,
            json.availableMachines,
            json.availableImplements,
            json.machineWeights,
            json.closed,
            json.notifyQueue,
            json.userData
        );
    }
}

export default Backend;