// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import dumbellImg from '../images/dumbell.png'
import barbellImg from '../images/barbell.png'
import machineImg from '../images/machine.png'
import implementsImg from '../images/implements.png'
import i18n from "../i18n/i18n";

const Thumbnail = withStyles({
    thumbnail: {
        padding: 0,
        backgroundColor: '#686666',
        border: 'none',
        maxWidth: 300,
        cursor: 'pointer',
        '&:hover': {
            backgroundTransparency: 90
        }
    },
    caption: {
        color: 'white',
        padding: 9
    },
    thumbnailImg: {
        display: 'block',
        maxWidth: '100%',
        height: 'auto'
    }
}) ((props) => (
    <Paper className={props.classes.thumbnail} onClick={props.onClick}>
        <img className={props.classes.thumbnailImg} src={props.image} alt="Гантели"/>
        <Typography variant="h6" className={props.classes.caption}>
            {props.caption}
        </Typography>
    </Paper>
));

const styles = () => ({
    root: {
        flexGrow: 1,
        maxWidth: 500,
        textAlign: 'center',
        marginRight: 'auto',
        marginLeft: 'auto'
    }
});

class MainMenu extends React.Component {

    static propTypes = {
        onDumbells: PropTypes.func,
        onBarbells: PropTypes.func,
        onMachines: PropTypes.func,
        onImplements: PropTypes.func,
    };

    handleClick = () => {
        alert(1)
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Thumbnail image={dumbellImg} caption={i18n.t('MainMenu.dumbell')} onClick={this.props.onDumbells}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Thumbnail image={barbellImg} caption={i18n.t('MainMenu.barbell')} onClick={this.props.onBarbells}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Thumbnail image={machineImg} caption={i18n.t('MainMenu.machine')} onClick={this.props.onMachines}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Thumbnail image={implementsImg} caption={i18n.t('MainMenu.implements')} onClick={this.props.onImplements}/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(MainMenu)