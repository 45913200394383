//@flow
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import i18n from '../i18n/i18n'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";

import unknownMachine from '../images/unknown_machine.png'
import horizontal_bench from '../images/horizontal_bench.png'
import step from '../images/step.png'
import bars from '../images/bars.png'
import inclinedBench from '../images/inclined_bench.png'
import fitball from '../images/fitball.png'
import rug from '../images/rug.png'
import rope from '../images/rope.png'
import treadmill from '../images/treadmill.png'
import horizontal_bar from '../images/horizontal_bar.png'
import roman_chair from '../images/roman_chair.png'
import stadium from '../images/stadium.png'

import * as _ from "lodash";
import classNames from "classnames";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = () => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#636066'
    },
    flex: {
        flex: 1,
    },
    dialogContent: {
        marginTop: 10,
        flexGrow: 1,
        maxWidth: 500,
        textAlign: 'center',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    equipment: {
        width: 100,
        height: 100,
        verticalAlign: 'middle',
        textAlign: 'center',
        borderRadius: 999,
        display: 'inline-block',
        backgroundColor: '#686667',
        margin: 4,
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        '-webkit-box-shadow': '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        position: 'relative',
        marginBottom: 10,
        '-webkit-transition': '.2s ease-out',
        transition: '.2s ease-out',
        '&:hover': {
            backgroundTransparency: '70%',
            '-webkit-box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)',
            'box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)'
        }
    },
    equipmentClicked: {
        backgroundColor: '#24d7ad!important'
    },
    equipmentLabel: {
        width: 70,
        height: 100,
        display: 'inline-block',
        backgroundImage:  "url('" + unknownMachine + "')",
        backgroundPosition: 'left',
        backgroundSize: 'contain',
        cursor: 'pointer',
        backgroundRepeat: 'no-repeat'
    },
    equipmentLabelHORIZONTAL_BENCH: {
        backgroundImage: "url('" + horizontal_bench + "')"
    },
    equipmentLabelSTEP: {
        backgroundImage: "url('" + step + "')"
    },
    equipmentLabelBARS: {
        backgroundImage: "url('" + bars + "')"
    },
    equipmentLabelINCLINED_BENCH: {
        backgroundImage: "url('" + inclinedBench + "')"
    },
    equipmentLabelFITBALL: {
        backgroundImage: "url('" + fitball + "')"
    },
    equipmentLabelRUG: {
        backgroundImage: "url('" + rug + "')"
    },
    equipmentLabelROPE: {
        backgroundImage: "url('" + rope + "')"
    },
    equipmentLabelSTADIUM: {
        backgroundImage: "url('" + stadium + "')"
    },
    equipmentLabelROMAN_CHAIR: {
        backgroundImage: "url('" + roman_chair + "')"
    },
    equipmentLabelHORIZONTAL_BAR: {
        backgroundImage: "url('" + horizontal_bar + "')"
    },
    equipmentLabelTREADMILL: {
        backgroundImage: "url('" + treadmill + "')"
    }
});

class ImplementsDialog extends React.Component {

    static allImplements = [
        {
            "id": "HORIZONTAL_BENCH",
        },
        {
            "id": "STEP",
        },
        {
            "id": "BARS",
        },
        {
            "id": "INCLINED_BENCH",
        },
        {
            "id": "FITBALL",
        },
        {
            "id": "RUG",
        },
        {
            "id": "ROPE",
        },
        {
            "id": "STADIUM",
        },
        {
            "id": "ROMAN_CHAIR",
        },
        {
            "id": "HORIZONTAL_BAR",
        },
        {
            "id": "TREADMILL",
        }
    ];

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        selected: PropTypes.array
    };

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            selected: []
        };
    }

    handleClose = () => {
        const props = this.props;

        if (props.onClose)
            props.onClose(this.state.selected);
    };

    handleEquipmentSelect = (impl) => {
        return () => {
            let selected = this.state.selected;

            if (_.indexOf(selected, impl.id) >= 0)
                selected = _.filter(selected, (n) => n !== impl.id);
            else
                selected.push(impl.id);

            this.setState({...this.state, selected: selected})
        }
    };

    equipmentLabelClass = (classes, impl) => {
      const cn = 'equipmentLabel' + impl.id;
      return classNames(classes.equipmentLabel, classes[cn])
    };

    equipmentClass = (classes, impl) => {
        if (_.indexOf(this.state.selected, impl.id) >= 0)
            return classNames(classes.equipment, classes.equipmentClicked);
        return classes.equipment;
    };

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        this.setState({
            ...this.state,
            selected: nextProps.selected == null ? [] : nextProps.selected
        });
    }

    render() {
        const {classes} = this.props;
        const open = this.props.open;

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            {i18n.t('ImplementsDialog.title')}
                        </Typography>
                        <IconButton href="#" color="inherit" onClick={this.handleClose}>
                            <Icon>close</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <div className={classes.dialogContent}>
                    <Typography color="inherit" className={classes.flex} variant="h6">
                        {i18n.t('ImplementsDialog.question')}
                    </Typography>

                    {ImplementsDialog.allImplements.map( (impl) =>
                        <div key={impl.id}>
                            <div className={this.equipmentClass(classes, impl)}>
                                <label className={this.equipmentLabelClass(classes, impl)} onClick={this.handleEquipmentSelect(impl)}>&nbsp;</label>
                            </div>
                            <Typography color="inherit" >
                                {i18n.t('ImplementsDialog.' + impl.id)}
                            </Typography>
                        </div>
                    )}
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles)(ImplementsDialog);