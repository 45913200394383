//@flow
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from "@material-ui/core/Grid";
import unknownMachine from "../images/unknown_machine.png";
import classNames from "classnames";
import horizontalDraft from '../images/horizontal_draft.png'
import hipAbductionMachine from '../images/hip_abduction_machine.png'
import legExtensionMachine from '../images/leg_extension_machine.png'
import seatedLegCurlMachine from '../images/seated_leg_curl_machine.png'
import gravitrol from '../images/gravitron.png'
import legPressMachine from '../images/leg_press_machine.png'
import shoulderPressMachine from '../images/shoulder_press_machine.png'
import hammer from '../images/hammer.png'
import scott_bench from '../images/scott_bench.png'
import pec_machine from '../images/pec_machine.png'
import crossover from '../images/crossover.png'
import verticalDraft from '../images/vertical_draft.png'
import chestPressMachine from '../images/chest_press_machine.png'
import hipAdductionMachine from '../images/hip_adduction_machine.png'
import * as _ from "lodash";
import i18n from "../i18n/i18n";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = () => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#636066'
    },
    flex: {
        flex: 1,
    },
    dialogContent: {
        marginTop: 10,
        flexGrow: 1,
        maxWidth: 500,
        textAlign: 'center',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    equipmentSection: {
      marginBottom: 10
    },
    equipment: {
        width: 100,
        height: 100,
        verticalAlign: 'middle',
        textAlign: 'center',
        borderRadius: 999,
        display: 'inline-block',
        backgroundColor: '#686667',
        margin: 4,
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        '-webkit-box-shadow': '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        position: 'relative',
        marginBottom: 10,
        '-webkit-transition': '.2s ease-out',
        transition: '.2s ease-out',
        '&:hover': {
            backgroundTransparency: '70%',
            '-webkit-box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)',
            'box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)'
        }
    },
    equipmentClicked: {
        backgroundColor: '#24d7ad!important'
    },
    equipmentLabel: {
        width: 70,
        height: 100,
        display: 'inline-block',
        backgroundImage:  "url('" + unknownMachine + "')",
        backgroundPosition: 'left',
        backgroundSize: 'contain',
        cursor: 'pointer',
        backgroundRepeat: 'no-repeat'
    },
    equipmentLabelHORIZONTAL_DRAFT: {
        backgroundImage: "url('" + horizontalDraft + "')"
    },
    equipmentLabelHIP_ABDUCTION_MACHINE: {
        backgroundImage: "url('" + hipAbductionMachine + "')"
    },
    equipmentLabelLEG_EXTENSION_MACHINE: {
        backgroundImage: "url('" + legExtensionMachine + "')"
    },
    equipmentLabelSEATED_LEG_CURL_MACHINE: {
        backgroundImage: "url('" + seatedLegCurlMachine + "')"
    },
    equipmentLabelGRAVITRON: {
        backgroundImage: "url('" + gravitrol + "')"
    },
    equipmentLabelLEG_PRESS_MACHINE: {
        backgroundImage: "url('" + legPressMachine + "')"
    },
    equipmentLabelSHOULDER_PRESS_MACHINE: {
        backgroundImage: "url('" + shoulderPressMachine + "')"
    },
    equipmentLabelHAMMER: {
        backgroundImage: "url('" + hammer + "')"
    },
    equipmentLabelSCOTT_BENCH: {
        backgroundImage: "url('" + scott_bench + "')"
    },
    equipmentLabelPEC_MACHINE: {
        backgroundImage: "url('" + pec_machine + "')"
    },
    equipmentLabelCROSSOVER: {
        backgroundImage: "url('" + crossover + "')"
    },
    equipmentLabelVERTICAL_DRAFT: {
        backgroundImage: "url('" + verticalDraft + "')"
    },
    equipmentLabelCHEST_PRESS_MACHINE: {
        backgroundImage: "url('" + chestPressMachine + "')"
    },
    equipmentLabelHIP_ADDUCTION_MACHINE: {
        backgroundImage: "url('" + hipAdductionMachine + "')"
    },
});

const WideToggleButtonGroup = withStyles({
    root: {
        width: '100%'
    }
})(ToggleButtonGroup);

const WideToggleButton = withStyles({
    root: {
        width: '100%'
    },
    selected: {
        backgroundColor: '#24d7ad!important'
    }
})(ToggleButton);

class MachineDialog extends React.Component {

    static allMachines = [
        {
            "id": "HORIZONTAL_DRAFT",
        },
        {
            "id": "HIP_ABDUCTION_MACHINE",
        },
        {
            "id": "LEG_EXTENSION_MACHINE",
        },
        {
            "id": "SEATED_LEG_CURL_MACHINE",
        },
        {
            "id": "GRAVITRON",
        },
        {
            "id": "LEG_PRESS_MACHINE",
        },
        {
            "id": "SHOULDER_PRESS_MACHINE",
        },
        {
            "id": "HAMMER",
        },
        {
            "id": "SCOTT_BENCH",
        },
        {
            "id": "PEC_MACHINE",
        },
        {
            "id": "CROSSOVER",
        },
        {
            "id": "VERTICAL_DRAFT",
        },
        {
            "id": "CHEST_PRESS_MACHINE",
        },
        {
            "id": "HIP_ADDUCTION_MACHINE",
        }
    ];

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        selected: PropTypes.array,
        selectedWeights: PropTypes.object,
    };

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            selected: this.props.selected,
            selectedWeights: this.props.selectedWeights
        };
    }

    handleClose = () => {
        const props = this.props;

        if (props.onClose)
            props.onClose(this.state.selected, this.state.selectedWeights);
    };

    handleEquipmentSelect = (impl) => {
        return () => {
            let selected = this.state.selected;
            let selectedWeights = this.state.selectedWeights;

            if (_.indexOf(selected, impl.id) >= 0) {
                selected = _.filter(selected, (n) => n !== impl.id);
                selectedWeights[impl.id] = null;
            } else {
                selected.push(impl.id);
                selectedWeights[impl.id] = 2.5;
            }

            this.setState({...this.state, selected: selected, selectedWeights: selectedWeights})
        }
    };

    handleWeightChange = (machineId) => {
        return (e, value) => {
            let selected = this.state.selectedWeights;
            selected[machineId] = value;
            this.setState({...this.state, selectedWeights: selected})
        }
    };

    isEquipmentSelected = (impl) => {
        return _.indexOf(this.state.selected, impl.id) >= 0;
    };

    equipmentLabelClass = (classes, impl) => {
        const cn = 'equipmentLabel' + impl.id;
        return classNames(classes.equipmentLabel, classes[cn])
    };

    equipmentClass = (classes, impl) => {
        if (_.indexOf(this.state.selected, impl.id) >= 0)
            return classNames(classes.equipment, classes.equipmentClicked);
        return classes.equipment;
    };

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        this.setState({
            ...this.state,
            selected: nextProps.selected == null ? [] : nextProps.selected,
            selectedWeights: nextProps.selectedWeights == null ? {} : nextProps.selectedWeights
        });
    }

    render() {
        const {classes} = this.props;
        const open = this.props.open;

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            {i18n.t('MachineDialog.title')}
                        </Typography>
                        <IconButton href="#" color="inherit" onClick={this.handleClose}>
                            <Icon>close</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <div className={classes.dialogContent}>
                    <Typography color="inherit" className={classes.flex} variant="h6">
                        {i18n.t('MachineDialog.question')}
                    </Typography>

                    {MachineDialog.allMachines.map( (m) =>
                        <div key={m.id} className={classes.equipmentSection}>
                            <div className={this.equipmentClass(classes, m)}>
                                <label className={this.equipmentLabelClass(classes, m)} onClick={this.handleEquipmentSelect(m)}>&nbsp;</label>
                            </div>
                            <Typography color="inherit" >
                                {i18n.t('MachineDialog.' + m.id)}
                            </Typography>
                            <Grid item xs={12} aria-label={12}>
                                <WideToggleButtonGroup
                                    value={this.state.selectedWeights[m.id]}
                                    exclusive
                                    onChange={this.handleWeightChange(m.id)}
                                    aria-label="text alignment"
                                    color="secondary"
                                    size="small"
                                >
                                    <WideToggleButton value={2.5} disabled={!this.isEquipmentSelected(m)}>
                                        2.5
                                    </WideToggleButton>
                                    <WideToggleButton value={5} disabled={!this.isEquipmentSelected(m)}>
                                        5
                                    </WideToggleButton>
                                    <WideToggleButton value={10} disabled={!this.isEquipmentSelected(m)}>
                                        10
                                    </WideToggleButton>
                                </WideToggleButtonGroup>
                            </Grid>
                            <Typography color="inherit" variant="caption">
                                {i18n.t('MachineDialog.machine_weight_step')}
                            </Typography>
                        </div>
                    )}
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles)(MachineDialog);