//@flow
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../i18n/i18n'
import * as _ from 'lodash';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import dumbell0 from "../images/dumbell0.png"
import dumbell1 from "../images/dumbell1.png"
import dumbell2 from "../images/dumbell2.png"
import dumbell3 from "../images/dumbell3.png"
import dumbell4 from "../images/dumbell4.png"
import dumbell5 from "../images/dumbell5.png"

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = () => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#636066'
    },
    flex: {
        flex: 1,
    },
    dialogContent: {
        marginTop: 10,
        flexGrow: 1,
        maxWidth: 500,
        textAlign: 'center',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    dumbell: {
        width: 100,
        height: 100,
        verticalAlign: 'middle',
        textAlign: 'center',
        borderRadius: 50,
        display: 'inline-block',
        backgroundColor: '#686667',
        margin: 4,
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        '-webkit-box-shadow': '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        position: 'relative',
        marginBottom: 10,
        '-webkit-transition': '.2s ease-out',
        transition: '.2s ease-out',
        '&:hover': {
            backgroundTransparency: '70%',
            '-webkit-box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)',
            'box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)'
        }
    },
    dumbellClicked: {
        backgroundColor: '#24d7ad!important'
    },
    dumbellLabel: {
        display: 'inline-block',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingTop: 15,
        color: 'white',
        cursor: 'pointer;',
        fontSize: '1.1rem',
        width: 100,
        height: 100
    },
    dumbellLabel0: {
        backgroundImage: "url('" + dumbell0 + "')",
        backgroundSize: '50%'
    },
    dumbellLabel1: {
        backgroundImage: "url('" + dumbell1 + "')",
        backgroundSize: '60%'
    },
    dumbellLabel2: {
        backgroundImage: "url('" + dumbell2 + "')",
        backgroundSize: '70%'
    },
    dumbellLabel3: {
        backgroundImage: "url('" + dumbell3 + "')",
        backgroundSize: '80%'
    },
    dumbellLabel4: {
        backgroundImage: "url('" + dumbell4 + "')",
        backgroundSize: '80%'
    },
    dumbellLabel5: {
        backgroundImage: "url('" + dumbell5 + "')",
        backgroundSize: '90%'
    }
});

class DumbellDialog extends React.Component {

    static allDumbells = [1, 2, 2.5, 3, 4, 5, 6, 7, 7.5, 8, 8.5, 9, 10, 11, 12, 12.5, 13.5, 14, 15, 16, 17.5, 18, 18.5, 20, 21, 22, 22.5, 23.5, 24, 25, 26, 27.5, 28, 28.5, 30, 31, 32, 32.5, 33.5, 34, 35, 36, 37.5, 38, 38.5, 40, 41, 42, 42.5, 43.5, 44, 45, 46, 47.5, 48, 48.5, 50, 51];

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        selected: PropTypes.array
    };

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            selected: []
        };
    }

    handleClose = () => {
        const props = this.props;

        if (props.onClose)
            props.onClose(this.state.selected);
    };

    dumbellLabelClasses = (classes, dumbell) => {
        const cn = 'dumbellLabel' + Math.round(dumbell/10);
        return classNames(classes.dumbellLabel, classes[cn])
    };

    dumbellClasses = (classes, dumbell) => {
        if (_.indexOf(this.state.selected, dumbell) >= 0)
            return classNames(classes.dumbell, classes.dumbellClicked);
        return classes.dumbell;
    };

    handleSelect = (dumbell) => {
        return () => {
            let selected = this.state.selected;

            if (_.indexOf(selected, dumbell) >= 0)
                selected = _.filter(selected, (n) => n !== dumbell);
            else
                selected.push(dumbell);

            this.setState({...this.state, selected: selected})
        }
    };

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        this.setState({
            ...this.state,
            selected: nextProps.selected == null ? [] : nextProps.selected
        });
    }

    render() {
        const {classes, open} = this.props;

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            {i18n.t('DumbellDialog.title')}
                        </Typography>
                        <IconButton href="#" color="inherit" onClick={this.handleClose}>
                            <Icon>close</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <div className={classes.dialogContent}>
                    <Typography color="inherit" className={classes.flex} variant="h6">
                        {i18n.t('DumbellDialog.question')}
                    </Typography>
                    <Grid container>
                        {DumbellDialog.allDumbells.map((dumbell) =>
                            <Grid item xs key={dumbell}>
                                <div className={this.dumbellClasses(classes, dumbell)}>
                                    <Typography variant="caption" className={this.dumbellLabelClasses(classes, dumbell)} onClick={this.handleSelect(dumbell)}>
                                        {dumbell}{i18n.t('kg')}
                                    </Typography>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles)(DumbellDialog);