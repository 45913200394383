//@flow
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import i18n from '../i18n/i18n'
import classNames from "classnames";
import barbellPlate125 from '../images/barbell-plate-125.png';
import barbellPlate250 from '../images/barbell-plate-250.png';
import barbellPlate500 from '../images/barbell-plate-500.png';
import barbellPlate1000 from '../images/barbell-plate-1000.png';
import barbellPlate1500 from '../images/barbell-plate-1500.png';
import barbellPlate2000 from '../images/barbell-plate-2000.png';
import barbellPlate2500 from '../images/barbell-plate-2500.png';
import barbellBar from '../images/barbell-bar.png';
import * as _ from "lodash";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = () => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#636066'
    },
    flex: {
        flex: 1,
    },
    wide: {
        width: '100%'
    },
    dialogContent: {
        marginTop: 10,
        flexGrow: 1,
        maxWidth: 500,
        textAlign: 'center',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    barbell: {
        verticalAlign: 'middle',
        textAlign: 'center',
        borderRadius: 999,
        display: 'inline-block',
        backgroundColor: '#686667',
        margin: 4,
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        '-webkit-box-shadow': '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
        position: 'relative',
        marginBottom: 10,
        '-webkit-transition': '.2s ease-out',
        transition: '.2s ease-out',
        '&:hover': {
            backgroundTransparency: '70%',
            '-webkit-box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)',
            'box-shadow': '0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)'
        }
    },
    barbellClicked: {
        backgroundColor: '#24d7ad!important'
    },
    barbellPlate: {
        width: 100,
        height: 100,
    },
    barbellBar: {
        width: 300,
        height: 75,
        textAlign: 'center'
    },
    barbellLabel: {
        display: 'inline-block',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        cursor: 'pointer;',
        fontSize: '1.1rem',
    },
    barbellPlateLabel: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: 100,
        height: 100
    },
    barbellBarLabel: {
        paddingTop: 10,
        height: 55,
        backgroundPosition: 'bottom',
        backgroundImage: "url('" + barbellBar + "')"
    },
    barbellPlateLabel125: {
        backgroundImage: "url('" + barbellPlate125 + "')"
    },
    barbellPlateLabel250: {
        backgroundImage: "url('" + barbellPlate250 + "')"
    },
    barbellPlateLabel500: {
        backgroundImage: "url('" + barbellPlate500 + "')"
    },
    barbellPlateLabel1000: {
        backgroundImage: "url('" + barbellPlate1000 + "')"
    },
    barbellPlateLabel1500: {
        backgroundImage: "url('" + barbellPlate1500 + "')"
    },
    barbellPlateLabel2000: {
        backgroundImage: "url('" + barbellPlate2000 + "')"
    },
    barbellPlateLabel2500: {
        backgroundImage: "url('" + barbellPlate2500 + "')"
    },
    barbellBarLabel10: {
        width: 240
    },
    barbellBarLabel15: {
        width: 260
    },
    barbellBarLabel20: {
        width: 280
    },
});

class BarbellDialog extends React.Component {

    static allBarbellBars = [10, 15, 20];
    static allBarbellPlates = [1.25, 2.5, 5, 10, 15, 20, 25];

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        selectedPlates: PropTypes.array,
        selectedBars: PropTypes.array,
    };

    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            selectedPlates: [],
            selectedBars: [],
        };
    }

    handleClose = () => {
        const props = this.props;

        if (props.onClose)
            props.onClose(this.state.selectedPlates, this.state.selectedBars);
    };

    barbellPlateLabelClasses = (classes, weight) => {
        const cn = 'barbellPlateLabel' + weight * 100;
        return classNames(classes.barbellLabel, classes.barbellPlateLabel, classes[cn])
    };

    barbellBarLabelClasses = (classes, weight) => {
        const cn = 'barbellBarLabel' + weight;
        return classNames(classes.barbellLabel, classes.barbellBarLabel, classes[cn])
    };

    barbellPlateClasses = (classes, weight) => {
        if (_.indexOf(this.state.selectedPlates, weight) >= 0)
            return classNames(classes.barbell, classes.barbellPlate, classes.barbellClicked);
        return classNames(classes.barbell, classes.barbellPlate);
    };

    barbellBarClasses = (classes, weight) => {
        if (_.indexOf(this.state.selectedBars, weight) >= 0)
            return classNames(classes.barbell, classes.barbellBar, classes.barbellClicked);
        return classNames(classes.barbell, classes.barbellBar);
    };

    handlePlateSelect = (weight) => {
        return () => {
            let selected = this.state.selectedPlates;

            if (_.indexOf(selected, weight) >= 0)
                selected = _.filter(selected, (n) => n !== weight);
            else
                selected.push(weight);

            this.setState({...this.state, selectedPlates: selected})
        }
    };

    handleBarSelect = (weight) => {
        return () => {
            let selected = this.state.selectedBars;

            if (_.indexOf(selected, weight) >= 0)
                selected = _.filter(selected, (n) => n !== weight);
            else
                selected.push(weight);

            this.setState({...this.state, selectedBars: selected})
        }
    };

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        this.setState({
            ...this.state,
            selectedPlates: nextProps.selectedPlates == null ? [] : nextProps.selectedPlates,
            selectedBars: nextProps.selectedBars == null ? [] : nextProps.selectedBars
        });
    }

    render() {
        const {classes} = this.props;
        const open = this.props.open;

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            {i18n.t('BarbellDialog.title')}
                        </Typography>
                        <IconButton href="#" color="inherit" onClick={this.handleClose}>
                            <Icon>close</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <div className={classes.dialogContent}>
                    <Typography color="inherit" className={classes.flex} variant="h6">
                        {i18n.t('BarbellDialog.plates_question')}
                    </Typography>
                    <Grid container>
                        {BarbellDialog.allBarbellPlates.map((weight) =>
                            <Grid item xs key={'plate' + weight}>
                                <div className={this.barbellPlateClasses(classes, weight)}>
                                    <label className={this.barbellPlateLabelClasses(classes, weight)} onClick={this.handlePlateSelect(weight)}>&nbsp;</label>
                                </div>
                            </Grid>
                        )}
                    </Grid>

                    <Typography color="inherit" className={classes.flex} variant="h6">
                        {i18n.t('BarbellDialog.bars_question')}
                    </Typography>
                    <Grid container>
                        {BarbellDialog.allBarbellBars.map((weight) =>
                            <Grid item className={classes.wide} key={'bar' + weight}>
                                <div className={this.barbellBarClasses(classes, weight)}>
                                    <Typography variant="caption" className={this.barbellBarLabelClasses(classes, weight)} onClick={this.handleBarSelect(weight)}>{weight}{i18n.t("kg")}</Typography>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>

            </Dialog>
        );
    }
}

export default withStyles(styles)(BarbellDialog);