// @flow
class ContextDTO {

    availableBarbellBars: Array<number>;
    availableBarbellPlates: Array<number>;
    availableDumbells: Array<number>;
    availableMachines: Array<string>;
    availableImplements: Array<string>;
    machineWeights: any;
    closed: boolean;
    notifyQueue: string;
    userData: any;


    constructor(
        availableBarbellBars: Array<number>,
        availableBarbellPlates: Array<number>,
        availableDumbells: Array<number>,
        availableMachines: Array<string>,
        availableImplements: Array<string>,
        machineWeights: any,
        closed: boolean,
        notifyQueue: string,
        userData: any
    ) {
        this.availableBarbellBars = availableBarbellBars;
        this.availableBarbellPlates = availableBarbellPlates;
        this.availableDumbells = availableDumbells;
        this.availableMachines = availableMachines;
        this.availableImplements = availableImplements;
        this.machineWeights = machineWeights;
        this.closed = closed;
        this.notifyQueue = notifyQueue;
        this.userData = userData;
    }
}

export default ContextDTO;