import i18next from 'i18next';
import enTranslation from './en.json'
import ruTranslation from './ru.json'
import iwTranslation from './iw.json'

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: navigator.language,
        fallbackLng: 'en',
        resources: {
            en: {
                translation: enTranslation
            },
            ru: {
                translation: ruTranslation
            },
            iw: {
                translation: iwTranslation
            },
            he: {
                translation: iwTranslation
            }
        },
    });

export default i18next